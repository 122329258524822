'use client'

import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useLoginMutation } from '@/lib/redux'
import { useRouter } from 'next/navigation'
import { useToast } from '../ui/use-toast'
import { authRoutes, siteRoutes } from '@/config/site'
import { SOMETHING_WRONG } from '@/config/messages'
import Link from 'next/link'
import { LuEye, LuEyeOff } from 'react-icons/lu'
import { useState } from 'react'
import { customErrorMap } from './errormapconfig'

const formSchema = z.object({
  email: z
    .string({
      errorMap: customErrorMap,
    })

    .email()
    .min(2)
    .max(50),
  password: z
    .string({
      errorMap: customErrorMap,
    })
    .min(8)
    .max(30),
})

export function LoginForm() {
  const [login, { isLoading }] = useLoginMutation()
  const { toast } = useToast()
  const [showPassword, setShowPassword] = useState(false)
  const router = useRouter()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await login(values).unwrap()
      router.push(siteRoutes.yourHub)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast({
        title: e.data.detail!,
        description: SOMETHING_WRONG,
        variant: 'destructive',
      })
    }
  }

  return (
    <>
      <h1 className='text-center font-ibm text-xl font-semibold'>Login to Your Account</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className=' w-full space-y-8'>
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    autoComplete='off'
                    type='email'
                    required
                    className='border-none bg-[#29292966]'
                    placeholder='Enter your Email here'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='password'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <div className='relative'>
                    <Input
                      autoComplete='off'
                      required
                      type={showPassword ? 'text' : 'password'}
                      className='border-none bg-[#29292966]'
                      placeholder='Enter your Password here'
                      {...field}
                    />
                    <div
                      onClick={onShowPassword}
                      className='absolute bottom-0 right-0 top-0 flex h-full cursor-pointer items-center justify-center px-3'
                    >
                      {showPassword ? <LuEye /> : <LuEyeOff />}
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type='submit'
            variant={'secondary'}
            disabled={isLoading}
            size='lg'
            className='w-full font-sora font-bold '
          >
            Login Now
          </Button>
        </form>
        <div className='text-center'>
          {`You don't have account? `}
          <Link className='text-sm text-primary' href={authRoutes.register}>
            Register
          </Link>
        </div>
      </Form>
    </>
  )
}
