import z from 'zod'

export const customErrorMap: z.ZodErrorMap = (issue, context) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    return {
      message: `${issue.path[0]}  is required`,
    }
  }
  if (issue.code === z.ZodIssueCode.too_big) {
    return {
      message: `${issue.path[0]}  is too big`,
    }
  }
  if (issue.code === z.ZodIssueCode.too_small) {
    return {
      message: `${issue.path[0]}  is too small`,
    }
  }

  return { message: context.defaultError }
}
