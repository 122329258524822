'use client'

import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google'
import { useLoginWithGoogleMutation } from '@/lib/redux'
import { Button } from '@/components/ui'
import { usePathname, useRouter } from 'next/navigation'
import { authRoutes, siteRoutes } from '@/config/site'
import { FaGoogle } from 'react-icons/fa'

const ContinueWithGoogleButton = () => {
  const [mutate] = useLoginWithGoogleMutation()
  const router = useRouter()
  const pathName = usePathname()

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await mutate({ token: tokenResponse.access_token }).unwrap()
      router.push(siteRoutes.yourHub)
    },
  })

  return (
    <Button
      className='h-9 w-full rounded-lg border-[#bbb]'
      onClick={() => {
        login()
      }}
      type={'button'}
      variant={'outline'}
    >
      <FaGoogle />
      <span className='ml-2 text-base'>
        {pathName === authRoutes.login ? 'Sign in with Google' : 'Sign up with Google'}
      </span>
    </Button>
  )
}

export function ContinueWithGoogle() {
  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!}>
      <ContinueWithGoogleButton />
    </GoogleOAuthProvider>
  )
}
